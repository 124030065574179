import React, { useState, useEffect } from 'react'
import { Star, HelpCircle, ArrowUpDown, X } from 'lucide-react'
import Select from 'react-select'

const API_KEY = process.env.REACT_APP_EXCHANGE_RATE_API_KEY;
const BASE_URL = `https://v6.exchangerate-api.com/v6/${API_KEY}`;

const currencies = {
  USD: { name: "US Dollar", flag: "🇺🇸" },
    EUR: { name: "Euro", flag: "🇪🇺" },
    GBP: { name: "British Pound", flag: "🇬🇧" },
    AED: { name: "UAE Dirham", flag: "🇦🇪" },
    AFN: { name: "Afghan Afghani", flag: "🇦🇫" },
    ALL: { name: "Albanian Lek", flag: "🇦🇱" },
    AMD: { name: "Armenian Dram", flag: "🇦🇲" },
    ANG: { name: "Netherlands Antillian Guilder", flag: "🇳🇱" },
    AOA: { name: "Angolan Kwanza", flag: "🇦🇴" },
    ARS: { name: "Argentine Peso", flag: "🇦🇷" },
    AUD: { name: "Australian Dollar", flag: "🇦🇺" },
    AWG: { name: "Aruban Florin", flag: "🇦🇼" },
    AZN: { name: "Azerbaijani Manat", flag: "🇦🇿" },
    BAM: { name: "Bosnia and Herzegovina Mark", flag: "🇧🇦" },
    BBD: { name: "Barbados Dollar", flag: "🇧🇧" },
    BDT: { name: "Bangladeshi Taka", flag: "🇧🇩" },
    BGN: { name: "Bulgarian Lev", flag: "🇧🇬" },
    BHD: { name: "Bahraini Dinar", flag: "🇧🇭" },
    BIF: { name: "Burundian Franc", flag: "🇧🇮" },
    BMD: { name: "Bermudian Dollar", flag: "🇧🇲" },
    BND: { name: "Brunei Dollar", flag: "🇧🇳" },
    BOB: { name: "Bolivian Boliviano", flag: "🇧🇴" },
    BRL: { name: "Brazilian Real", flag: "🇧🇷" },
    BSD: { name: "Bahamian Dollar", flag: "🇧🇸" },
    BTN: { name: "Bhutanese Ngultrum", flag: "🇧🇹" },
    BWP: { name: "Botswana Pula", flag: "🇧🇼" },
    BYN: { name: "Belarusian Ruble", flag: "🇧🇾" },
    BZD: { name: "Belize Dollar", flag: "🇧🇿" },
    CAD: { name: "Canadian Dollar", flag: "🇨🇦" },
    CDF: { name: "Congolese Franc", flag: "🇨🇩" },
    CHF: { name: "Swiss Franc", flag: "🇨🇭" },
    CLP: { name: "Chilean Peso", flag: "🇨🇱" },
    CNY: { name: "Chinese Renminbi", flag: "🇨🇳" },
    COP: { name: "Colombian Peso", flag: "🇨🇴" },
    CRC: { name: "Costa Rican Colon", flag: "🇨🇷" },
    CUP: { name: "Cuban Peso", flag: "🇨🇺" },
    CVE: { name: "Cape Verdean Escudo", flag: "🇨🇻" },
    CZK: { name: "Czech Koruna", flag: "🇨🇿" },
    DJF: { name: "Djiboutian Franc", flag: "🇩🇯" },
    DKK: { name: "Danish Krone", flag: "🇩🇰" },
    DOP: { name: "Dominican Peso", flag: "🇩🇴" },
    DZD: { name: "Algerian Dinar", flag: "🇩🇿" },
    EGP: { name: "Egyptian Pound", flag: "🇪🇬" },
    ERN: { name: "Eritrean Nakfa", flag: "🇪🇷" },
    ETB: { name: "Ethiopian Birr", flag: "🇪🇹" },
    FJD: { name: "Fiji Dollar", flag: "🇫🇯" },
    FKP: { name: "Falkland Islands Pound", flag: "🇫🇰" },
    FOK: { name: "Faroese Króna", flag: "🇫🇴" },
    GEL: { name: "Georgian Lari", flag: "🇬🇪" },
    GGP: { name: "Guernsey Pound", flag: "🇬🇬" },
    GHS: { name: "Ghanaian Cedi", flag: "🇬🇭" },
    GIP: { name: "Gibraltar Pound", flag: "🇬🇮" },
    GMD: { name: "Gambian Dalasi", flag: "🇬🇲" },
    GNF: { name: "Guinean Franc", flag: "🇬🇳" },
    GTQ: { name: "Guatemalan Quetzal", flag: "🇬🇹" },
    GYD: { name: "Guyanese Dollar", flag: "🇬🇾" },
    HKD: { name: "Hong Kong Dollar", flag: "🇭🇰" },
    HNL: { name: "Honduran Lempira", flag: "🇭🇳" },
    HRK: { name: "Croatian Kuna", flag: "🇭🇷" },
    HTG: { name: "Haitian Gourde", flag: "🇭🇹" },
    HUF: { name: "Hungarian Forint", flag: "🇭🇺" },
    IDR: { name: "Indonesian Rupiah", flag: "🇮🇩" },
    ILS: { name: "Israeli New Shekel", flag: "🇮🇱" },
    IMP: { name: "Manx Pound", flag: "🇮🇲" },
    INR: { name: "Indian Rupee", flag: "🇮🇳" },
    IQD: { name: "Iraqi Dinar", flag: "🇮🇶" },
    IRR: { name: "Iranian Rial", flag: "🇮🇷" },
    ISK: { name: "Icelandic Króna", flag: "🇮🇸" },
    JEP: { name: "Jersey Pound", flag: "🇯🇪" },
    JMD: { name: "Jamaican Dollar", flag: "🇯🇲" },
    JOD: { name: "Jordanian Dinar", flag: "🇯🇴" },
    JPY: { name: "Japanese Yen", flag: "🇯🇵" },
    KES: { name: "Kenyan Shilling", flag: "🇰🇪" },
    KGS: { name: "Kyrgyzstani Som", flag: "🇰🇬" },
    KHR: { name: "Cambodian Riel", flag: "🇰🇭" },
    KID: { name: "Kiribati Dollar", flag: "🇰🇮" },
    KMF: { name: "Comorian Franc", flag: "🇰🇲" },
    KRW: { name: "South Korean Won", flag: "🇰🇷" },
    KWD: { name: "Kuwaiti Dinar", flag: "🇰🇼" },
    KYD: { name: "Cayman Islands Dollar", flag: "🇰🇾" },
    KZT: { name: "Kazakhstani Tenge", flag: "🇰🇿" },
    LAK: { name: "Lao Kip", flag: "🇱🇦" },
    LBP: { name: "Lebanese Pound", flag: "🇱🇧" },
    LKR: { name: "Sri Lanka Rupee", flag: "🇱🇰" },
    LRD: { name: "Liberian Dollar", flag: "🇱🇷" },
    LSL: { name: "Lesotho Loti", flag: "🇱🇸" },
    LYD: { name: "Libyan Dinar", flag: "🇱🇾" },
    MAD: { name: "Moroccan Dirham", flag: "🇲🇦" },
    MDL: { name: "Moldovan Leu", flag: "🇲🇩" },
    MGA: { name: "Malagasy Ariary", flag: "🇲🇬" },
    MKD: { name: "Macedonian Denar", flag: "🇲🇰" },
    MMK: { name: "Burmese Kyat", flag: "🇲🇲" },
    MNT: { name: "Mongolian Tögrög", flag: "🇲🇳" },
    MOP: { name: "Macanese Pataca", flag: "🇲🇴" },
    MRU: { name: "Mauritanian Ouguiya", flag: "🇲🇷" },
    MUR: { name: "Mauritian Rupee", flag: "🇲🇺" },
    MVR: { name: "Maldivian Rufiyaa", flag: "🇲🇻" },
    MWK: { name: "Malawian Kwacha", flag: "🇲🇼" },
    MXN: { name: "Mexican Peso", flag: "🇲🇽" },
    MYR: { name: "Malaysian Ringgit", flag: "🇲🇾" },
    MZN: { name: "Mozambican Metical", flag: "🇲🇿" },
    NAD: { name: "Namibian Dollar", flag: "🇳🇦" },
    NGN: { name: "Nigerian Naira", flag: "🇳🇬" },
    NIO: { name: "Nicaraguan Córdoba", flag: "🇳🇮" },
    NOK: { name: "Norwegian Krone", flag: "🇳🇴" },
    NPR: { name: "Nepalese Rupee", flag: "🇳🇵" },
    NZD: { name: "New Zealand Dollar", flag: "🇳🇿" },
    OMR: { name: "Omani Rial", flag: "🇴🇲" },
    PAB: { name: "Panamanian Balboa", flag: "🇵🇦" },
    PEN: { name: "Peruvian Sol", flag: "🇵🇪" },
    PGK: { name: "Papua New Guinean Kina", flag: "🇵🇬" },
    PHP: { name: "Philippine Peso", flag: "🇵🇭" },
    PKR: { name: "Pakistani Rupee", flag: "🇵🇰" },
    PLN: { name: "Polish Złoty", flag: "🇵🇱" },
    PYG: { name: "Paraguayan Guaraní", flag: "🇵🇾" },
    QAR: { name: "Qatari Riyal", flag: "🇶🇦" },
    RON: { name: "Romanian Leu", flag: "🇷🇴" },
    RSD: { name: "Serbian Dinar", flag: "🇷🇸" },
    RUB: { name: "Russian Ruble", flag: "🇷🇺" },
    RWF: { name: "Rwandan Franc", flag: "🇷🇼" },
    SAR: { name: "Saudi Riyal", flag: "🇸🇦" },
    SBD: { name: "Solomon Islands Dollar", flag: "🇸🇧" },
    SCR: { name: "Seychellois Rupee", flag: "🇸🇨" },
    SDG: { name: "Sudanese Pound", flag: "🇸🇩" },
    SEK: { name: "Swedish Krona", flag: "🇸🇪" },
    SGD: { name: "Singapore Dollar", flag: "🇸🇬" },
    SHP: { name: "Saint Helena Pound", flag: "🇸🇭" },
    SLE: { name: "Sierra Leonean Leone", flag: "🇸🇱" },
    SOS: { name: "Somali Shilling", flag: "🇸🇴" },
    SRD: { name: "Surinamese Dollar", flag: "🇸🇷" },
    SSP: { name: "South Sudanese Pound", flag: "🇸🇸" },
    STN: { name: "São Tomé and Príncipe Dobra", flag: "🇸🇹" },
    SYP: { name: "Syrian Pound", flag: "🇸🇾" },
    SZL: { name: "Eswatini Lilangeni", flag: "🇸🇿" },
    THB: { name: "Thai Baht", flag: "🇹🇭" },
    TJS: { name: "Tajikistani Somoni", flag: "🇹🇯" },
    TMT: { name: "Turkmenistan Manat", flag: "🇹🇲" },
    TND: { name: "Tunisian Dinar", flag: "🇹🇳" },
    TOP: { name: "Tongan Paʻanga", flag: "🇹🇴" },
    TRY: { name: "Turkish Lira", flag: "🇹🇷" },
    TTD: { name: "Trinidad and Tobago Dollar", flag: "🇹🇹" },
    TVD: { name: "Tuvaluan Dollar", flag: "🇹🇻" },
    TWD: { name: "New Taiwan Dollar", flag: "🇹🇼" },
    TZS: { name: "Tanzanian Shilling", flag: "🇹🇿" },
    UAH: { name: "Ukrainian Hryvnia", flag: "🇺🇦" },
    UGX: { name: "Ugandan Shilling", flag: "🇺🇬" },
    UYU: { name: "Uruguayan Peso", flag: "🇺🇾" },
    UZS: { name: "Uzbekistani So'm", flag: "🇺🇿" },
    VES: { name: "Venezuelan Bolívar", flag: "🇻🇪" },
    VND: { name: "Vietnamese Đồng", flag: "🇻🇳" },
    VUV: { name: "Vanuatu Vatu", flag: "🇻🇺" },
    WST: { name: "Samoan Tālā", flag: "🇼🇸" },
    XAF: { name: "Central African CFA Franc", flag: "🇨🇲" },
    XCD: { name: "East Caribbean Dollar", flag: "🇰🇳" },
    XDR: { name: "Special Drawing Rights", flag: "🏳️" },
    XOF: { name: "West African CFA Franc", flag: "🇨🇮" },
    XPF: { name: "CFP Franc", flag: "🇵🇫" },
    YER: { name: "Yemeni Rial", flag: "🇾🇪" },
    ZAR: { name: "South African Rand", flag: "🇿🇦" },
    ZMW: { name: "Zambian Kwacha", flag: "🇿🇲" },
    ZWL: { name: "Zimbabwean Dollar", flag: "🇿🇼" }
};

const currencyOptions = Object.entries(currencies).map(([code, { name, flag }]) => ({
  value: code,
  label: `${flag} ${code} - ${name}`,
  searchTerms: `${code} ${name}`.toLowerCase()
}));

const customFilter = (option, inputValue) => {
  return option.data.searchTerms.includes(inputValue.toLowerCase());
};

export default function App() {
  const [amount, setAmount] = useState(0)
  const [fromCurrency, setFromCurrency] = useState('USD')
  const [toCurrency, setToCurrency] = useState('EUR')
  const [convertedAmount, setConvertedAmount] = useState(null)
  const [favorites, setFavorites] = useState([])
  const [showFavorites, setShowFavorites] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [autoConvert, setAutoConvert] = useState(false)
  const [lastClickedFavorite, setLastClickedFavorite] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (autoConvert && amount > 0) {
      handleConvert()
    }
  }, [amount, fromCurrency, toCurrency, autoConvert])

  const handleConvert = async () => {
    setError(null);
    if (!amount) {
      setError("Please enter an amount to convert.");
      setConvertedAmount(null);
      return;
    }
    if (fromCurrency === toCurrency) {
      setError("Please select different currencies for conversion.");
      setConvertedAmount(null);
      return;
    }
    try {
      const url = `${BASE_URL}/latest/${fromCurrency}`;
      console.log(`Fetching from: ${url}`);
      
      const response = await fetch(url);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to convert currency');
      }
      
      const rate = data.conversion_rates[toCurrency];
      if (!rate) {
        throw new Error('Invalid currency conversion rate');
      }
      
      const result = amount * rate;
      setConvertedAmount(result);
    } catch (error) {
      console.error('Error converting currency:', error);
      setError("An error occurred while converting.\nPlease try again.");
      setConvertedAmount(null);
    }
  }

  const handleSwap = () => {
    setFromCurrency(toCurrency)
    setToCurrency(fromCurrency)
  }

  const toggleFavorite = (currency) => {
    if (favorites.includes(currency)) {
      setFavorites(favorites.filter(fav => fav !== currency))
    } else {
      setFavorites([...favorites, currency])
    }
  }

  const handleFavoriteClick = (currency) => {
    if (lastClickedFavorite === null) {
      setFromCurrency(currency);
      setLastClickedFavorite(currency);
    } else if (currency !== lastClickedFavorite) {
      setToCurrency(currency);
      setLastClickedFavorite(null);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-blue-600 to-purple-600 flex flex-col font-sans">
      <div className="flex-grow flex flex-col w-full max-w-md mx-auto px-4 py-6">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden w-full">
          <div className="bg-blue-900 text-white py-4 px-6 flex justify-between items-center">
            <h3 className="text-xl font-bold">ConvertPrice.com</h3>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowFavorites(!showFavorites)}
                className="relative"
              >
                {showFavorites ? (
                  <Star className="h-7 w-7 fill-yellow-500 text-yellow-500" />
                ) : (
                  <Star className="h-7 w-7" />
                )}
                {favorites.length > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {favorites.length}
                  </span>
                )}
              </button>
              <button
                onClick={() => setShowHelp(!showHelp)}
                className={`p-1 rounded-full ${showHelp ? 'bg-yellow-500' : ''}`}
              >
                {showHelp ? (
                  <X className="h-7 w-7 text-white" />
                ) : (
                  <HelpCircle className="h-7 w-7" />
                )}
              </button>
            </div>
          </div>

          {showHelp ? (
            <div className="p-6 text-base">
              <h2 className="text-xl font-bold mb-4">Help</h2>
              <p className="mb-4">Welcome to <b>ConvertPrice.com</b> app. Here's how to use it:</p>
              <ol className="list-decimal list-inside space-y-3">
                <li>Enter the amount you want to convert in the input field.</li>
                <li>Select or search for the currency you're converting from.</li>
                <li>Select or search for the currency you're converting to.</li>
                <li>Click the <b>Convert</b> button to see the result.</li>
                <li>Use <b>Auto Convert</b> for automatic updates as you change values.</li>
                <li><b>Star icon ☆</b> adds selected currency to favorites for quick access.</li>
                <li>Click <b>Star icon ⭐︎</b> on top bar to show favourited currencies.</li>
                <li>Click <b>favourite currency</b> to add it to conversion.</li>
                <li>Use the swap button (↑↓) to switch between currencies.</li>
              </ol>
              <p className="mt-4">⚫️ We use real-time exchange rates for accurate conversions.</p>
              <p className="mt-2">⚫️ Questions? Contact: <b>support@convertprice.com</b></p>
            </div>
          ) : (
            <>
              {showFavorites && favorites.length > 0 && (
                <div className="px-6 py-4 border-b overflow-x-auto">
                  <div className="flex gap-4 pb-2">
                    {favorites.map(currencyCode => {
                      const currency = currencies[currencyCode];
                      return currency ? (
                        <button
                          key={currencyCode}
                          onClick={() => handleFavoriteClick(currencyCode)}
                          className="text-center hover:bg-gray-100 p-3 rounded-lg flex-shrink-0"
                        >
                          <div className="text-2xl">{currency.flag}</div>
                          <div className="text-sm mt-1">{currencyCode}</div>
                        </button>
                      ) : null;
                    })}
                  </div>
                </div>
              )}

              <div className="p-6 space-y-6">
                <div className="flex items-center">
                  <div className="bg-green-500 text-white text-2xl font-normal p-3 rounded-l-lg w-14 flex items-center justify-center border border-green-500">$</div>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full p-3 border border-l-0 rounded-r-lg text-2xl font-semibold"
                    placeholder="Enter amount"
                  />
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Select
                      options={currencyOptions}
                      value={currencyOptions.find(option => option.value === fromCurrency) || null}
                      onChange={(selectedOption) => setFromCurrency(selectedOption ? selectedOption.value : 'USD')}
                      className="flex-grow"
                      classNamePrefix="react-select"
                      isSearchable={true}
                      filterOption={customFilter}
                      placeholder="From Currency"
                      aria-label="From Currency"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: '48px',
                          borderRadius: '0.5rem',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 2,
                        }),
                      }}
                    />
                    <button onClick={() => toggleFavorite(fromCurrency)}>
                      <Star
                        className={`h-8 w-8 ${
                          favorites.includes(fromCurrency)
                            ? 'text-yellow-500 fill-yellow-500'
                            : 'text-gray-300'
                        }`}
                      />
                    </button>
                  </div>
                  
                  <button
                    onClick={handleSwap}
                    className="w-full flex items-center justify-center p-3 my-2 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-600 transition-colors"
                  >
                    <ArrowUpDown className="h-6 w-6 mr-2" />
                    Swap
                  </button>

                  <div className="flex items-center space-x-2">
                    <Select
                      options={currencyOptions}
                      value={currencyOptions.find(option => option.value === toCurrency) || null}
                      onChange={(selectedOption) => setToCurrency(selectedOption ? selectedOption.value : 'EUR')}
                      className="flex-grow"
                      classNamePrefix="react-select"
                      isSearchable={true}
                      filterOption={customFilter}
                      placeholder="To Currency"
                      aria-label="To Currency"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: '48px',
                          borderRadius: '0.5rem',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 2,
                        }),
                      }}
                    />
                    <button onClick={() => toggleFavorite(toCurrency)}>
                      <Star
                        className={`h-8 w-8 ${
                          favorites.includes(toCurrency)
                            ? 'text-yellow-500 fill-yellow-500'
                            : 'text-gray-300'
                        }`}
                      />
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={handleConvert}
                    className="p-3 bg-green-500 text-white rounded-lg font-semibold text-lg hover:bg-green-600 transition-colors"
                  >
                    Convert
                  </button>
                  <button
                    onClick={() => setAutoConvert(!autoConvert)}
                    className={`p-3 ${autoConvert ? 'bg-purple-600' : 'bg-blue-500'} text-white rounded-lg font-semibold text-lg hover:opacity-90 transition-opacity`}
                  >
                    Auto Convert
                  </button>
                </div>

                <div
                  className={`p-6 rounded-lg text-center ${
                    error ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                  }`}
                >
                  {error ? (
                    <span className="font-medium text-lg">{error}</span>
                  ) : (
                    <>
                      <span className="text-base text-gray-600">
                        {amount} {currencies[fromCurrency]?.name || 'Unknown Currency'}
                      </span>
                      <br />
                      <span className="text-4xl font-bold my-2 block">
                        {convertedAmount ? convertedAmount.toFixed(2) : '---'}
                      </span>
                      <span className="text-base font-semibold">
                        {currencies[toCurrency]?.name || 'Unknown Currency'}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}